const selectMonth = (e) => {
  let monthsSelected = document.getElementsByClassName("month-selected");
  for (let i = 0; i < monthsSelected.length; i++)
    monthsSelected[i].classList.remove("month-selected");

  document.getElementById(e.target.id).classList.add("month-selected");

  let month = e.target.id;

  const url = new URL(window.location.href);
  url.searchParams.set('month', month);
  window.location.href = url.href;
}

const slide = () => {
  const slider = document.querySelector('.months');

  let isDown = false;
  let startX;
  let scrollLeft;
  const offset = 140;

  let selectedMonth = document.getElementsByClassName("month-selected")[0];
  if(!!selectedMonth)
    slider.scroll({ left: selectedMonth.getBoundingClientRect().left - offset, behavior: "smooth" })

  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });

  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });

  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });

  slider.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX); //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  });
}

const statementsPage = () => {
  window.toggleDropdown = (title) => {
    document.getElementById(title).classList.toggle("drop-close");
    document.getElementById(`${title}-title`).classList.toggle("opened-drop");
  }

  let months = document.getElementsByClassName("month-select");

  const url = new URL(window.location.href);
  if(!!url.searchParams.get("month"))
    document.getElementById(`${url.searchParams.get("month")}`).classList.add("month-selected");
  else if(months.length > 0) {
    months[0].classList.add("month-selected");
    let month = months[0].id;
    url.searchParams.set('month', month);
    window.location.href = url.href;
  }

  for (let i = 0; i < months.length; i++)
    months[i].addEventListener('click', selectMonth);

  slide();
}

export { statementsPage }
