import {Controller} from "@hotwired/stimulus"
import {Chart, LinearScale, Tooltip} from "chart.js"
import {MatrixController, MatrixElement} from 'chartjs-chart-matrix';

Chart.register(MatrixController, MatrixElement, LinearScale, Tooltip);
export default class extends Controller {
  static values = {data: Array}

  canvasContext() {
    return this.element.getContext('2d');
  }

  connect() {
    console.log(this.dataValue);
    const canvas = document.createElement('canvas');
    // Append the canvas to this.element
    this.element.appendChild(canvas);

    const data = this.dataValue;

    const config = {
      type: 'matrix',
      data: {
        datasets: [{
          label: 'Valor da Parcela',
          data: data,
          borderWidth: 1,
          borderColor: 'rgba(0,0,0,0.5)',
          backgroundColor: 'rgba(253,182,90,0.68)',
          width(c) {
            const a = c.chart.chartArea || {};
            return (a.right - a.left) / 12 - 1;
          },
          height(c) {
            const a = c.chart.chartArea || {};
            const years = data.map(d => d.y); // Extract the years from your data
            const minYear = Math.min(...years);
            const maxYear = Math.max(...years);
            let numYears = maxYear - minYear + 1;
            if (numYears === 1) {
              numYears = 2;
            }
            return (a.bottom - a.top) / numYears - 1;
          }
        }],
      },
      options: {
        aspectRatio: 4,
        offset: true,
        plugins: {
          tooltip: {
            enabled: true,
            callbacks: {
              title: function () {
                return `Parcela`;
              },
              label: function (tooltipItem) {
                return [`Número: ${tooltipItem.raw.number}`, `Valor: R$ ${tooltipItem.raw.amount}`, ];
              }
            }
          },
        },
        scales: {
          x: {
            min: 1,
            max: 12,
            grid: {
              display: false // This will hide the grid lines on the x axis
            },
            ticks: {
              stepSize: 1,
              callback: function (value, index, values) {
                const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
                return months[value - 1];
              }
            },
          },
          y: {
            offset: true,
            ticks: {
              stepSize: 1,
              callback: function (value, index, values) {
                return value.toString(); // Convert the value to a string to prevent thousand separators
              },
            },
            grid: {
              display: false,
              drawBorder: false,
              tickLength: 0,
            }
          }
        }
      }
    };

    new Chart(this.canvasContext(), config);
  }
}