import Turbo from "turbolinks"
Turbo.start()
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()
import "../channels"

// External imports
import "bootstrap";
import "chartkick/chart.js"

// Internal imports
import "../controllers"
import { modals } from "@/modals/modals"
import { statementsPage } from "@/statements";

document.addEventListener('turbolinks:load', () => {
    // Call your functions here, e.g:
    // initSelect2();
    modals();
    statementsPage();
});
